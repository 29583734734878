@import 'bootstrap/dist/css/bootstrap.min.css';

.App {
  text-align: center;
  min-height: 100vh;
}

* {
  box-sizing: border-box;

}

/* *home */


.image-col,
.text-col {
  text-align: start;
  padding: 10px;
}

/* */

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

header {
  height: 110px;
  align-items: center;
  padding-bottom: 5%;

}

header a {
  text-decoration: none;
  color: #4D4D4D;
  font-weight: 600;

}

header a:hover {
  color: #161616;
  text-decoration: underline;
}

a.activelink {
  font-weight: bold;
  text-decoration: underline;
  color: #161616;
}

.aboutcv {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 15px;
}

.aboutcv span {
  font-weight: bold;
}

.aboutskill {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 15px;
}

.contact {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 15px;
}

footer a {
  color: #161616;
  text-decoration: none;
  cursor: pointer;
}

.aboutprofile {
  text-align: left;
  padding-top: 10px;
}

.getcontact {
  text-align: left;
  padding-top: 10px;
}

.workhostnav a {
  color: #161616;
  text-decoration: none;
  font-weight: 300;
}

.workhostnav a:hover {
  color: #161616;
  text-decoration: none;
  font-weight: 300;
}

a.activelink {
  text-decoration: none;
  color: #161616;
}

.btncontact {
  background-color: #FFEAD0;
  color: #4D4D4D;

}

.profileimage{
  margin-top: 20px;
  padding-left: 10px;
}

.portcards {
  padding-top: 20px;
  padding-bottom: 20px;
}

.portcards {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pcontainer {
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
}

@media screen and (max-width: 992px) {
  .pcontainer {
    display: grid;
    grid-column: span 3;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }


}

@media screen and (max-width: 768px) {
  .pcontainer {
    display: block;
  }

  .portwrapper {
    padding-bottom: 10px;
  }
}

.portwrapper {
  color: #1a1a1a;
  text-align: center;

}

.content {
  position: relative;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: auto;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-top {
  top: 20%;
}


.van-type {
  height: 34px;
  padding: 6px 26px;
  font-style: normal;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  background-color: #FFEAD0;
  color: #4D4D4D;
  transition: 200ms all cubic-bezier(0.4, 0, 0.2, 1);
}

.van-type:hover {
  color: #FFEAD0;
}

.van-type:focus {
  outline: none;
}

.van-type.selected {
  color: #4D4D4D;
}

.react {
  margin: 10px;
}

.javascript {
  margin: 10px;
}

.van-type.react:hover,
.van-type.react.selected {
  background-color: #E17654;

}

.van-type.javascript:hover,
.van-type.javascript.selected {
  background-color: #115E59;
}


.van-type.clearfilters {
  margin-left: -20px;
  height: 34px;
  padding: 6px 26px;
  font-style: normal;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  text-decoration: underline;
  background-color: transparent;
  color: #4D4D4D;
}

i[class*='van-type-'] {
  padding: 7px 14px;
  color: #4D4D4D;
}

.portfilterbtn {
  margin: 20px;

}

.filterbtn {
  padding: 10px;
}

.econtainer {

  padding: 10px;
}


.scrollbox {
  overflow: auto;
  width: auto;
  max-height: 400px;
  justify-content: center;
  align-items: center;
  /*--g:radial-gradient( closest-side at 55% 20px, #0009, #0000);  
 background: 
    linear-gradient(#fff 10px, #0000 40px calc(100% - 40px),#fff calc(100% - 10px)) local, 
    var(--g) top   /100% 200%,    
    var(--g) bottom/100% 200%;
    margin: 20px; */

}



/*
@media screen and (max-width:430px) {
  .econtainer {
    display: block !important;
    width: 100% !important;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  .scrollbox{
    width: 600px;
  max-height: 400px;
  justify-content: center;
  align-items: center; 
  }
}
@media screen and (max-width: 992px) {
  .econtainer{
    display: grid;
    grid-column: span 3;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

}

*/
/* archive */
/* 
.card{
  width: 100%;
  position: relative;
  display: inline-block;
  background-color: #E17654;
  
}
   */

.card-archive img {
  width: 100%;
  padding: 10px;
}


.results-archive {
  /*max-width: 2000px; */
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  text-align: center;
  text-decoration: none;

}

@media (min-width: 600px) {

  .results-archive {
    column-count: 3;
  }

  @media (min-results: 1000px) {
    .results-archive {
      column-count: 4;
    }
  }

  @media (min-width: 1400px) {
    .results-archive {
      column-count: 5;
    }
  }
}

/* detail */
.text-description {
  padding: 20px;
  text-align: center;
  /* Centers text horizontally */
  display: flex;
  /* Enables vertical centering */
  align-items: center;
  /* Centers text vertically within the row */
}

/* architecture */


.masonry {
  /* Masonry container */
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 1em;
  -moz-column-gap: 1em;
  column-gap: 1em;
  margin: 1.5em;
  padding: 0;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em;
  font-size: .85em;
}

.item {
  display: inline-block;
  background: #fff;
  padding: 1em;
  margin: 0 0 1.5em;
  width: 100%;
  -webkit-transition: 1s ease all;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 2px 2px 4px 0 #ccc;
}

.item img {
  max-width: 100%;
}

@media only screen and (max-width: 320px) {
  .masonry {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media only screen and (min-width: 1201px) {
  .masonry {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}

/* Modal */
.close {
  margin: 20px;
  cursor: pointer;
  float: right; 

}

.modalcontainer{
  padding-top: 5px;
  margin-top: 10px;
}
.modalimg{
  padding-top: 10px;
}
.modal-header h2 {
  padding: 10px;
  text-align: center;
  justify-content: center;
}
.modal-title{
  padding: 10px;
  text-align: center;
  justify-content: center;
}